import React, { useState, useEffect } from 'react';

// * IMPORTS HELPERS
import { Global } from '../../helpers/Global';

// * IMPORTS MATERIAl UI - CONTAINER AND GRID
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

// * IMPORT DATATABLES
import MUIDataTable from "mui-datatables";

export const Sending = () => {

    // Estado para almacenar todos los usuarios
    const [dataUsers, setDataUsers] = useState([]);

    // Conseguir a todos los usuarios
    useEffect(() => {
        getUsers().then(result => {
            console.log(result);
            setDataUsers(result.users);
        });
    }, []);

    const getUsers = async () => {
        // Preparar peticion a API
        var raw = JSON.stringify({
            "table": "customs_colombia"
        });

        let requestOptions = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Basic dmVudG9zRGV2OmRldlZlbnRvMDcwNDIwMjJA`
            },
            body: raw,
            redirect: 'follow'
        };

        let request = await fetch(`${Global.url_api}api/mailing-users`, requestOptions);
        let response = await request.json();
        return response;
    }

    const columns = [
        {
            name: "NOMBRE",
            label: "Nombre"
        },
        {
            name: "EMAIL",
            label: "Email"
        },
        {
            name: "MODEL",
            label: "Modelo"
        },
        {
            name: "STATUS",
            label: "Estatus"
        }
    ];

    const options = {
        filterType: 'checkbox',
        responsive: "standard",
        textLabels: {
            body: {
                noMatch: "Lo sentimos, no se encontraron registros coincidentes.",
                toolTip: "Sort",
                columnHeaderTooltip: column => `Sort for ${column.label}`
            },
            pagination: {
                next: "Siguiente pagina",
                previous: "Pagina anterior",
                rowsPerPage: "Filas por pagina:",
                displayRows: "de",
            },
            toolbar: {
                search: "Buscar",
                downloadCsv: "Descargar CSV",
                print: "Imprimir",
                viewColumns: "Ver columnas",
                filterTable: "Tabla de filtros",
            },
        }
    };


    return (

        <div>

            <Container component="main" maxWidth="xxl">
                <Box sx={{ width: '100%', mt: 5 }}>
                    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12} sm={12} md={12} >
                            <MUIDataTable
                                title={"Clientes Colombia"}
                                data={dataUsers}
                                columns={columns}
                                options={options}
                            /> 
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </div>
    )
}
