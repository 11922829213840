import React from 'react';
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
import { PublicLayout } from '../components/layouts/PublicLayout';
import { Sending } from '../components/Mailing/Sending';

export const Routing = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/mailing" element={<PublicLayout />}>
                    <Route path='sending' element={<Sending />} />
                </Route>
            </Routes>
        </BrowserRouter>
    )
}
