import React from 'react';
import { Outlet } from 'react-router-dom';
import { Header } from './Header';
import { Footer } from './Footer';

export const PublicLayout = () => {
    return (
        <>
            {/* LAYOUT */}
            <Header />

            <section>
                <Outlet />
            </section>

            <Footer />
        </>
    )
}
