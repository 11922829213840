import './App.css';
import { Routing } from './router/Routing';


function App() {
	return (
		<div className="">
			{/* Cargando toda la configuracion de rutas */}
			<Routing />
		</div>
	);
}

export default App;
